<template>
  <fragment>
    <div class="main_page">
      <app-navbar></app-navbar>
      <div class="page">
        <div>
          <div class="page-title">
            <h1>Purchase Failed</h1>
          </div>

          <div class="page-content">
            <div class="row d-flex justify-content-end">
              <menu class="d-flex align-items-center justify-content-end m-0">
                <router-link :to="{ name: 'project-info' }" title="Back to Project">Back to Project</router-link>
              </menu>
            </div>

            <p> An error has occured and your transaction has been cancelled - please contact an administrator. </p>
            <p> <strong> Transaction Id: </strong> {{ transactionId }} </p>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import AppNavBar from "@/components/app-navbar.vue";

export default {
  components: {
    "app-navbar": AppNavBar
  },
  data: function () {
    return {
      transactionId: null
    }
  },
  mounted () {
    this.transactionId = this.$route.query.transId;
  }
};
</script>
